<template>
    <div class="creationCompetitionOne">
        <el-form :rules="rules" ref="form" :model="form" label-width="80px" label-position="left">
            <el-form-item label="训练名称" prop="name">
                <el-input style="width: 500px;" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="训练介绍">
                <el-input type="textarea" :rows="4" style="width: 500px;" v-model="form.introduce"></el-input>
            </el-form-item>
            <el-form-item label="行业分类" prop="industryClassify">
                <el-select v-model="form.industryClassify" placeholder="请选择行业分类" style="width: 500px;">
                    <el-option v-for="(item,index) in industryList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="训练时长" prop="duration">
                <el-input style="width: 200px;" v-model="form.duration" @input="trainingTime"  oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                <span style="margin-left: 10px">分钟，训练将在创建成功5分钟后开启，您可以在训练管理列表中立即开启</span>
            </el-form-item>
            <el-form-item label="队伍" prop="teamClassify">
                <el-select v-model="form.teamClassify" placeholder="请选择队伍" style="width: 500px;">
                    <el-option v-for="(item,index) in teamList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="upDownBtn">
            <el-button @click="toBack">上一步</el-button>
            <el-button @click="toNext" style="background: #1122D8;border-color:#1122D8;color: #ffffff;margin-left: 20px">下一步</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "trainAdminCreationCompetitionOne",
        data(){
            return {
                form:{
                    name:'',
                    introduce:'',
                    industryClassify:'',
                    duration:'',
                    teamClassify:''
                },
                editForm:{},
                industryList:[],
                teamList:[],
                rules:{
                    name: [
                        { required: true, message: '请输入训练名称', trigger: 'blur' },
                        { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
                    ],
                    industryClassify: [
                        { required: true, message: '请选择行业分类', trigger: 'change' }
                    ],
                    teamClassify: [
                        { required: true, message: '请选择队伍', trigger: 'change' }
                    ],
                    duration: [
                        { required: true, message: '请输入训练时长', trigger: 'blur' },
                        { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ]
                },
            }
        },
        created() {
            this.getEditData();
            this.getCategoryList()
        },
        methods: {
            getEditData(){
                if(this.$route.query.editRes){
                    this.$http.axiosGetBy(this.$api.getCom, {com_id:this.$route.query.editRes},res=>{
                        if(res.code === 200){
                            console.log('res', res.data);
                            this.editForm = res.data;
                            this.form.name = res.data.name;
                            this.form.industryClassify = res.data.category_id;
                            this.form.teamClassify = Number(res.data.c_id);
                            this.form.duration = res.data.duration;
                            if(res.data.introduce){
                                this.form.introduce = res.data.introduce;
                            }
                            let val = res.data.duration
                            this.trainingTime(val, this.form.teamClassify)
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            },
            getCategoryList(){
                this.$http.axiosGetBy(this.$api.categoryList, {}, res=>{
                    if(res.code === 200){
                        this.industryList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            trainingTime(val, class_id=null){
              let begin_time = Date.parse(new Date()) / 1000;
              let end_time = begin_time + Number(val) * 60;
                let param = {
                    begin_time:begin_time,
                    end_time:end_time
                };
                if (class_id) {
                    param.class_id = class_id
                }
                this.$http.axiosGetBy(this.$api.classes, param, res=>{
                    if(res.code === 200){
                        this.teamList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            toBack(){
                this.$router.push({
                    path:'/trainadmin/trainmanage'
                })
            },
            toNext(){
                if(this.form.name && this.form.industryClassify && this.form.duration && this.form.teamClassify){
                    let formParam = JSON.stringify(this.form);
                    if(this.$route.query.editRes){
                        let editForm = JSON.stringify(this.editForm);
                        this.$router.push({
                            path:'/trainadmin/trainmanage/trainadmincreationcompetitiontwo',
                            query:{
                                res:formParam,
                                editForm:editForm,
                                editRes:this.$route.query.editRes
                            }
                        })
                    } else {
                        this.$router.push({
                            path:'/trainadmin/trainmanage/trainadmincreationcompetitiontwo',
                            query:{
                                res:formParam,
                            }
                        })
                    }
                } else {
                    this.$message.warning('必填项不能为空！')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .creationCompetitionOne{
        padding:40px 0 70px 120px;
    }
    .upDownBtn{
        padding-left: 80px;
    }
</style>